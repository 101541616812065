import React, { FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Box } from "@material-ui/core"
import i18next from "i18next"
import _isEmpty from "lodash.isempty"

import { ApiError } from "../../enums/ApiErrorCode"
import { useAppSelector } from "../../hooks/storeHooks"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
import { useCreateExamOrderMutation } from "../../store/examReferrals/examReferrals.api"
import { selectChosenLaboratory } from "../../store/medicalExamsPoints/medicalExamsPoints.selectors"
import theme from "../../themes/default/theme"
import ActionButtons from "../actionButtons/ActionButtons.component"
import PageSection from "../common/pageSection/PageSection.component"
import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import HiddenInput from "../commonFormItems/hiddenInput/HiddenInput.component"
import AlabSearchEngine from "./alabSearchEngine/AlabSearchEngine.component"
import ExaminationDate from "./ExaminationDate/ExaminationDate.component"
import ExaminationList from "./ExaminationList/ExaminationList.component"
import FormField from "./FormField/FormField.component"
import SummaryView from "./summaryView/SummaryView.component"
import TermsOfServiceAndPrivacyPolicy from "./termsOfServiceAndPrivacyPolicy/TermsOfServiceAndPrivacyPolicy.component"
import { ExaminationOrder } from "../../store/examReferrals/examReferrals.types"
import { ConsultationExamReferral } from "../exam/Exam.types"
import { useOrderExaminationsByAlabForm } from "./OrderExaminationsByAlabForm.styles"

interface OrderExaminationsByAlabFormProps {
  referrals: ConsultationExamReferral[];
  consultationId?: string;
  visitAtDate: string;
  cancelButtonAction?: () => void;
}

const OrderExaminationsByAlabForm: FC<OrderExaminationsByAlabFormProps> = ({
  referrals,
  consultationId,
  visitAtDate,
  cancelButtonAction
}) => {
  const { t, i18n } = useTranslation()
  const { push } = useHistory()
  const classes = useOrderExaminationsByAlabForm()
  const [handleCreateExamOrder, {
    data,
    error,
    isLoading,
    isError,
    isSuccess
  }] = useCreateExamOrderMutation()
  const chosenLaboratory =  useAppSelector(selectChosenLaboratory)
  const {
    clinicSignalIdunaSettings: { insuranceVerificationEnabled },
    frontendSettings: { modules },
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)
  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled
  const isInsuranceVerificationEnabled = insuranceVerificationEnabled || modules.includes("s7health") || isAllianzEnabled

  const formMethods = useForm<ExaminationOrder>({
    defaultValues: {
      referrals: referrals.filter(referral => !referral.is_bought && referral.included_in_package),
      executeDate: new Date(),
      consultationId: consultationId,
      language: i18n.language,
      laboratory: null,
    }
  })

  useEffect(() => {
    formMethods.setValue("laboratory", chosenLaboratory ? chosenLaboratory : null)
  }, [chosenLaboratory])

  if (isSuccess) {
    if (
      data?.status === "redirect"
        && !data?.redirect?.includes("patient/exam/order")
    ) {
      window.location.href = data?.redirect
    } else {
      return (
        <SummaryView
          executeDate={formMethods.watch("executeDate")}
          response={data}
          orderedExams={formMethods.getValues("referrals")}
        />
      )
    }
  }
  
  if (_isEmpty(formMethods.getValues("referrals"))) {
    push(`/${i18next.language}`)

    return null
  }

  return (
    <PageSection title={t("exam:orderExams")}>
      <FormProvider {...formMethods}>
        <form
          className={classes.root}
          onSubmit={formMethods.handleSubmit(handleCreateExamOrder)}
        >
          <Box
            mb={7}
            p={5.5}
            borderRadius={theme.shape.borderRadius}
            component="section"
            bgcolor={theme.palette.background.paper}
          >
            <HiddenInput name="consultationId" />
            <HiddenInput name="language" />
            <FormField
              title={`1. ${t("referrals:examinationList")}`}
            >
              <ExaminationList/>
            </FormField>
            <FormField
              title={`2. ${t("referrals:dateOfExamination")}`}
            >
              <ExaminationDate
                visitAtDate={visitAtDate}
              />
            </FormField>
            <FormField
              title={`3. ${t("referrals:nearbyLaboratory")}`}
            >
              <AlabSearchEngine/>
            </FormField>
            {
              isError && (
                <GlobalFormErrorMessage 
                  message={
                    t(
                      // @ts-ignore
                      error?.data?.errorType === ApiError.UNCOVERED_EXAMS_IN_INSURANCE_PACKAGE
                        ? "exam:uncoveredExamsError"
                        : "errors:unknownError"
                    )}
                />
              )
            }
            <ActionButtons
              isLoading={isLoading}
              cancelButtonAction={cancelButtonAction}
              submitBtnLabel={isAllianzEnabled ? t("exam:orderExams") : undefined}
              isInsuranceVerificationEnabled={isInsuranceVerificationEnabled}
              shouldDisable={true}
            />
            <Box
              display="flex"
              justifyContent={
                isInsuranceVerificationEnabled
                  ? "flex-end"
                  : "space-between"
              }
            >
              <TermsOfServiceAndPrivacyPolicy/>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </PageSection>
  )
}

export default OrderExaminationsByAlabForm
