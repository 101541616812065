import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core"
import clsx from "clsx"
import { format } from "date-fns"

import pharmacy from "../../../assets/images/pharmacy.png"
import visit from "../../../assets/images/visit.png"
import { useAppSelector } from "../../../hooks/storeHooks"
import { dateFnsTranslations } from "../../../i18n/dateFnsTranslations"
import { ExaminationOrderSuccessResponse } from "../../../store/examReferrals/examReferrals.api"
import { selectChosenLaboratory } from "../../../store/medicalExamsPoints/medicalExamsPoints.selectors"
import theme from "../../../themes/default/theme"
import PageSection from "../../common/pageSection/PageSection.component"
import PickupSection from "../../orderMedicinesForm/thankYouPageView/pickupSection/PickupSection.component"
import ActionButtons from "./actionButtons/ActionButtons.component"
import { ConsultationExamReferral } from "../../exam/Exam.types"
import { useSummaryViewStyles } from "./SummaryView.styles"

interface SummaryViewProps {
  executeDate: string | Date;
  response?: ExaminationOrderSuccessResponse;
  orderedExams: ConsultationExamReferral[];
}

const SummaryView: FC<SummaryViewProps> = ({ 
  executeDate,
  response,
  orderedExams  
}) => {
  const {t, i18n} = useTranslation()
  const classes = useSummaryViewStyles()
  const chosenLaboratory = useAppSelector(selectChosenLaboratory)
  const formattedDate = format(
    typeof executeDate === "string"
      ? new Date(executeDate)
      : executeDate,
    "eee d MMMM",
    { locale: dateFnsTranslations[i18n.language as keyof typeof dateFnsTranslations] }
  )

  const formattedDateWithYear = format(
    typeof executeDate === "string"
      ? new Date(executeDate)
      : executeDate,
    "dd.MM.yyyy",
    { locale: dateFnsTranslations[i18n.language as keyof typeof dateFnsTranslations] }
  )

  return (
    <>
      <PageSection>
        <Box
          display="flex"
          flexDirection="column"
          mb={7}
          p={5.5}
          borderRadius={theme.shape.borderRadius}
          component="section"
          bgcolor={theme.palette.background.paper}
        >
          <Grid
            container
          >
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.borderRight}
            >
              <Typography
                variant="h2"
                gutterBottom
              >
                {t("referrals:summaryHeader")}
              </Typography>
              <Typography
                variant="body1"
              >
                {t("referrals:alabReminder",{point: chosenLaboratory?.pointType.toUpperCase()})}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.laboratoryColumn}
            >
              <PickupSection
                title={t("consultation:stationaryDetailsView:when")}
                labels={[formattedDate]}
                image={visit}
                imagePath={"visit.png"}
              />
              {
                chosenLaboratory && (
                  <PickupSection
                    title={t("referrals:nearestLaboratory")}
                    labels={
                      [
                        `${chosenLaboratory?.pointType.toUpperCase()}`,
                        `${chosenLaboratory?.street}, ${chosenLaboratory?.postalCode} ${chosenLaboratory?.city}`
                      ]
                    }
                    image={pharmacy}
                    imagePath={"pharmacy.png"}
                  />
                )
              }
            </Grid>
          </Grid>
          {response?.uncoveredExams && response?.uncoveredExams.length >= 0 && (
            <Box mt={2}>
              <Typography
                variant="h4"
                gutterBottom
              >
                {t("exam:uncoveredExamsTableTitle")} ({formattedDateWithYear})
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow hover>
                      <TableCell component="th">{t("exam:index")}</TableCell>
                      <TableCell component="th">{t("exam:labName")}</TableCell>
                      <TableCell component="th">{t("exam:status")}</TableCell>
                      <TableCell component="th">{t("exam:details")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      [...orderedExams]
                        .sort((a, b) => a.lab_name.localeCompare(b.lab_name))
                        .map((exam, index) => (
                          <TableRow hover key={exam.id}>
                            <TableCell>
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              {exam.lab_name}
                            </TableCell>
                            <TableCell>
                              {
                                response?.uncoveredExams?.some(uncoveredExam => uncoveredExam.examOrder === Number(exam.id))
                                  ? <span className={clsx(classes.statusBadge, classes.statusFailed)}>{t("exam:failedOrder")}</span>
                                  : <span className={clsx(classes.statusBadge, classes.statusSuccess)}>{t("exam:successOrder")}</span>
                              }
                            </TableCell>
                            <TableCell>
                              {
                                response?.uncoveredExams?.find(uncoveredExam => uncoveredExam.examOrder == Number(exam.id))?.exception || "-"
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <ActionButtons/>
        </Box>
      </PageSection>
    </>
  )
}

export default SummaryView
