import {createStyles, makeStyles} from "@material-ui/core"

export const useSummaryViewStyles = makeStyles(theme => createStyles({
  root: {
    padding: theme.spacing(2)
  },
  buttonsWrapper: {
    marginTop: theme.spacing(4)
  },
  primaryButton: {
    marginRight: theme.spacing(3)
  },
  borderRight: {
    paddingRight: theme.spacing(9),
    borderRight: `2px solid ${theme.palette.grey.A100}`,
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
      paddingRight: theme.spacing(0),
    }
  },
  laboratoryColumn: {
    paddingLeft: theme.spacing(9),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(0),
    }
  },
  statusBadge: {
    display: "inline-block",
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.50)}px`,
    borderRadius: theme.spacing(2.25),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    width: "100%",
    textAlign: "center"
  },
  statusSuccess: {
    backgroundColor: "#d1fae5",
    color: "#065f46"
  },
  statusFailed: {
    backgroundColor: "#fecaca",
    color: "#991b1b"
  }
}))
