import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@material-ui/core"

import { useExamRowStyles } from "./ExamRow.styles"

interface ExamRowProps {
  name: string;
  includedInPackage?: boolean;
  isBought?: boolean;
}

const ExamRow: FC<ExamRowProps> = ({ 
  name,
  includedInPackage,
  isBought
}) => {
  const classes = useExamRowStyles()
  const { t } = useTranslation()

  const getExamDescription = () => {
    if (isBought) {
      return t("referrals:alreadyBought")
    }

    return t(includedInPackage ? "referrals:includedInPackage" : "referrals:notIncludedInPackage")
  }

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Typography
        color={
          includedInPackage || includedInPackage === null 
            ? "textPrimary" 
            : "textSecondary"
        }
        gutterBottom
      >
        {name}
      </Typography>
      {includedInPackage !== null && (
        <Typography
          variant="subtitle1"
          color={includedInPackage ? "initial" : "error"}
        >
          {getExamDescription()}
        </Typography>
      )}
    </Box>
  )
}

export default ExamRow
