import React, { FC } from "react"
import { useFieldArray } from "react-hook-form"
import { Box } from "@material-ui/core"

import Examination from "./Examination/Examination.component"
import { OrderExaminationsByAlabFormData } from "../OrderExaminationsByAlabForm.types"

const ExaminationList: FC = () => {
  const { fields, remove } = useFieldArray<OrderExaminationsByAlabFormData, "referrals">({
    name: "referrals"
  })
  
  return (
    <Box>
      {
        [...fields]
          .sort((a, b) => a.lab_name.localeCompare(b.lab_name))
          .map((field, index) => (
            <Examination
              key={field.id}
              name={field.lab_name}
              onRemove={() => remove(index)}
              isDisabledRemove={fields.length === 1}
            />
          ))
      }
    </Box>
  )
}

export default ExaminationList
