import React, {FC} from "react"
// import {useTranslation} from "react-i18next"
import {Box} from "@material-ui/core"

// import {alabListUrl} from "../../../app.config"
// import {ReactComponent as InfoCircle} from "../../../assets/svg/infoCircle.svg"
import ActionButtons from "./ActionButtons/ActionButtons.component"
import ExamRow from "./ExamRow/ExamRow.component"
import {ConsultationExamReferral} from "../../exam/Exam.types"
// import {useExaminationSectionStyles} from "./ExaminationSection.styles"

interface ExaminationSectionProps {
  examinations: ConsultationExamReferral[];
  isBought: boolean;
  isNoExaminationIncludedInPackage: boolean;
  consultationId: string;
  referralId?: string;
  date?: string;
}

const ExaminationSection: FC<ExaminationSectionProps> = ({ 
  examinations,
  referralId,
  date,
  consultationId,
  isBought,
  isNoExaminationIncludedInPackage
}) => {
  // const {t} = useTranslation()
  // const classes = useExaminationSectionStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      {/* LESS-2290 - [FE] Ukrycie komunikatu gdzie można wykonać umawiane badania */}
      {/* <Box
        display="flex"
        flexDirection="row"
        className={classes.infoWrapper}
      >
        <InfoCircle
          className={classes.infoIcon}
        />
        <Typography
          variant="body2"
        >
          {t("referrals:referralValidity")}{" "}
          <Link
            target="_blank"
            href={alabListUrl}
          >
            {t("referrals:showList")}
          </Link>
        </Typography>
      </Box> */}
      {/* e/o LESS-2290 - [FE] Ukrycie komunikatu gdzie można wykonać umawiane badania */}
      {
        [...examinations]
          .sort((a, b) => a.lab_name.localeCompare(b.lab_name))
          .map(examination => (
            <ExamRow
              key={examination.id}
              name={examination.lab_name}
              includedInPackage={examination.included_in_package}
              isBought={examination.is_bought}
            />
          ))
      }
      <ActionButtons
        isOrderButtonDisabled={isBought || isNoExaminationIncludedInPackage}
        date={date}
        referralId={referralId}
        consultationId={consultationId}
      />
    </Box>
  )
}

export default ExaminationSection
