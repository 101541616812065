import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Button } from "@material-ui/core"
import Box from "@material-ui/core/Box"

import api from "../../../../api/api"
import { getMedicalDocumentFileConfig } from "../../../../api/routes"
import { useAppSelector } from "../../../../hooks/storeHooks"
import useFileDownload from "../../../../hooks/useFileDownload"
import { selectClinicSettings } from "../../../../store/clinic/clinicSettings.selectors"
import GlobalErrorSnackbar from "../../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
import { RoutePath} from "../../../../routes/Routes.types"
import { useActionButtonsStyles } from "./ActionButtons.styles"

interface ActionButtonsProps {
  isOrderButtonDisabled: boolean;
  consultationId: string;
  referralId?: string;
  date?: string;
}

const ActionButtons: FC<ActionButtonsProps> = ({
  isOrderButtonDisabled,
  referralId,
  date,
  consultationId,
}) => {
  const { t, i18n } = useTranslation()
  const classes = useActionButtonsStyles()
  const fileName = `${date} ${t("referrals:referral")}`
  const getReferralPdf = () => api.request<Blob>({...getMedicalDocumentFileConfig(referralId || "")})
  const { download, downloading, downloadError } = useFileDownload(getReferralPdf)
  const {
    clinicAllianzSettings: {
      ewkaVerificationEnabled,
      onyxVerificationEnabled
    }
  } = useAppSelector(selectClinicSettings)
  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled
  
  const handleDownloadPdfClick = () => {
    download(fileName)
  }

  return (
    <Box
      className={classes.buttonsWrapper}
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
    >
      <Button
        variant="outlined"
        color="secondary"
        className={classes.actionButton}
        disabled={!referralId || downloading}
        onClick={handleDownloadPdfClick}
      >
        {t("referrals:download")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.actionButton}
        disabled={isOrderButtonDisabled}
        component={Link}
        to={`/${i18n.language}${RoutePath.ORDER_EXAMINATIONS_BY_ALAB.replace(":consultationId", consultationId)}`}
      >
        {t(isAllianzEnabled ? "exam:orderExams" : "referrals:order")}
      </Button>
      <GlobalErrorSnackbar isError={!!downloadError}/>
    </Box>
  )
}

export default ActionButtons
